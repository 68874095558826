<template>
	<div>
		
	</div>
</template>

<script>
	export default{
		
		data(){
			return {
				token:''
			}
		},
		created(){
			console.log(this.$route.query);
			let keys = Object.keys(this.$route.query);
			console.log(keys);
			let token = keys[0];
			if(!token){
				this.$message.error('地址不正确');
			}else{
				this.token = token;
				this.login();
			}
		},
		methods:{
			login(){
				this.$http.api('platform/account/dabashan',{
					token:this.token
				}).then(res=>{
					localStorage.setItem('account-token',res.auth_token);
					this.$message.success('登录成功',1,()=>{
						this.$router.push('/mendian');
					})
				}).catch(res=>{
					console.log(res);
				});
			}
		},
	}
</script>

<style>
</style>